import React from 'react'
import { Link } from 'react-router-dom'

const Consultation = () => {
  return (
    <div>
        <Link ></Link>
        Consultation</div>
  )
}

export default Consultation